@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: #1f232b;
}

.bg-list {
  background: #282c37;
}

.bg-list-row-even {
  background-color: #1f232b;
}

.bg-list-row-even-hover {
  background-color: #282c37;
}

.bg-list-row-odd {
  background-color: #282c37;
}

.bg-list-row-odd-hover {
  background-color: #1f232b;
}